import './bootstrap';
import '../css/app.css';

import { createApp, h, DefineComponent } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const vueInstance = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue);

        Sentry.init({
            app: vueInstance,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        });

        // Set Sentry user context
        const authUser = props.initialPage.props.auth?.user;
        if (authUser) {
            Sentry.setUser({
                id: authUser.id,
                email: authUser.email,
            });
        }

        vueInstance.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
